<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selectedFeed">
    <template v-slot:default="props">
      <form>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inspect Feed</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">
              <!-- Feed Details -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Feed Details</p>
                <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                  <tbody>
                    <tr>
                      <th style="width:164px">ID</th>
                      <td>{{ props.record.id }}</td>
                    </tr>
                    <tr>
                      <th>Feed Type</th>
                      <td>{{ $utils.upperFirst(props.record.direction) }} {{ $utils.upperFirst(props.record.data_type) }}</td>
                    </tr>
                    <!-- filename is not available in the record in the merchant context -->
                    <tr>
                      <th>Original Filename</th>
                      <td>{{ props.record.original_filename ? props.record.original_filename : '--' }}</td>
                    </tr>
                    <!-- template is not available in the record in the merchant context -->
                    <tr>
                      <th>Template</th>
                      <td>{{ props.record.template ? props.record.template.name : '--' }}</td>
                    </tr>
                    <tr>
                      <th>File Link</th>
                      <td>
                        <DownloadFileAs label="Download" :cloudFileName="props.record.cloud_filename" :originalFileName="props.record.original_filename"/>
                      </td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td class="has-text-weight-bold">
                        <span v-if="props.record.status === 'success'" class="has-text-success">Success</span>
                        <span v-else-if="props.record.status === 'failure'" class="has-text-danger">Failed</span>
                        <span v-else-if="props.record.status === 'processing'" class="has-text-danger">Processing</span>
                        <span v-else>Queued</span>
                      </td>
                    </tr>
                    <tr v-if="props.record.status === 'queued'">
                      <td></td>
                      <td>
                        <b-button
                          type="is-primary"
                          size="is-small"
                          :loading="runningFeed"
                          :disabled="runningFeed"
                          @click="enqueue"
                        >Process Feed</b-button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Feed Timestamps -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Feed Timestamps</p>
                <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                  <tbody>
                    <tr>
                      <th style="width:164px">Created At</th>
                      <td>{{ $dates.utc(props.record.created_at).local().format('MMM D, YYYY @ h:mm A') }}</td>
                    </tr>
                    <tr>
                      <th>Started At</th>
                      <td>
                        <span v-if="props.record.processed_at">{{ $dates.utc(props.record.processed_at).local().format('MMM D, YYYY @ h:mm A') }}</span>
                        <span v-else class="has-text-grey is-italic">Not Started</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Completed At</th>
                      <td>
                        <span v-if="props.record.completed_at">{{ $dates.utc(props.record.completed_at).local().format('MMM D, YYYY @ h:mm A') }}</span>
                        <span v-else class="has-text-grey is-italic">Not Completed</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Wait time</th>
                      <td>
                        <Runtime :start="props.record.created_at" :end="props.record.processed_at" />
                      </td>
                    </tr>
                    <tr>
                      <th>Processing Time</th>
                      <td>
                        <Runtime :start="props.record.processed_at" :end="props.record.completed_at" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Feed Results -->
              <div class="mb-md">
                <p class="has-text-weight-bold mb-sm">Feed Results</p>
                <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
                  <tbody>
                    <tr>
                      <th style="width:164px"># of Rows</th>
                      <td>{{ props.record.num_items }}</td>
                    </tr>
                    <!-- Products & Inventory -->
                    <tr>
                      <th># of Items Created</th>
                      <td>{{ props.record.num_created }}</td>
                    </tr>
                    <tr>
                      <th># of Items Updated</th>
                      <td>{{ props.record.num_updated }}</td>
                    </tr>
                    <!-- All -->
                    <tr>
                      <th># of Warnings</th>
                      <td>{{ props.record.num_warnings }}</td>
                    </tr>
                    <tr>
                      <th># of Errors</th>
                      <td>{{ props.record.num_errors }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </form>
    </template>
  </InspectionModal>
</template>

<script>
import InspectionModal from '../InspectionModal'
import { mapGetters } from 'vuex'
import Runtime from '@/components/Models/FeedQueue/Presenters/Runtime'

export default {
  components: {
    InspectionModal,
    Runtime
  },
  data () {
    return {
      loadDetail: true,
      runningFeed: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.selectedFeed !== null) {
        let path = this.context.route + '/feed-queue/' + this.selectedFeed.id + '/'
        if (this.context.isMerchant()) {
          path = this.context.route + '/feed-queue/' + this.selectedFeed.id + '/?connections=1'
        }
        return path
      }
      return null
    }
  },
  methods: {
    enqueue () {
      this.runningFeed = true
      const endpoint = this.endpoint + 'run/'
      this.$http.put(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Feed queued!' })
        this.$parent.close()
      }).finally(() => {
        this.runningFeed = false
      })
    }
  },
  mounted () {

  },
  props: ['selectedFeed']
}
</script>
