<template>
  <div>
    <!-- Filters Toolbar -->
    <div class="has-background-white rounded-lg pa-md mb-xxs">
      <div class="columns">
        <div class="column">
          <ProposalProductFilters :applied-filters="filters" @filter="filter" />
        </div>
        <div class="column is-narrow">
          <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
            <option value="-id">Most Recent</option>
            <option value="id">Oldest</option>
          </b-select>
        </div>
      </div>
    </div>
    <!-- Actions Toolbar -->
    <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column">
          <b-dropdown :disabled="loading || selected.length === 0">
            <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
              <span>Actions</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>
            <template>
              <b-dropdown-item :disabled="context.isSupplier()" @click="showCreateIssueModal = true">Create Issue</b-dropdown-item>
            </template>
            <template>
              <b-dropdown-item :disabled="context.isSupplier()" @click="showAddToOpenIssueModal = true">Add to Open Issue</b-dropdown-item>
            </template>
            <hr class="dropdown-divider"/>
            <template v-if="canRemoveItems">
              <b-dropdown-item has-link><a href="#" @click.prevent="showRemoveProductsModal = true">Remove Items</a></b-dropdown-item>
            </template>
            <template v-else>
              <b-dropdown-item custom><span class="has-text-grey-light">No Additional Actions</span></b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
        <div class="column">
          <div class="has-text-right is-size-7">
            <!-- Counts -->
            <p v-if="loading">Loading...</p>
            <p v-else>{{ total }} {{ (total === 1) ? 'Product' : 'Products' }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div class="has-background-white rounded-lg pa-md">
      <div class="columns">
        <div class="column">
          <b-table
          :data="results"
          :mobile-cards="false"
          :loading="loading"
          :checked-rows.sync="selected"
          checkable
          hoverable
          selec
          class="is-size-7">
          <template>
            <!-- Image -->
            <b-table-column
              :visible="true"
              label=""
              cell-class="nowrap"
              width="96"
              v-slot="props">
              <div v-if="props.row.primary_media">
                <p><a :href="props.row.primary_media.url|cloudinary" target="_blank"><img class="rounded-lg" :src="props.row.primary_media.url | cloudinary(60,60,'fill')" width="60" height="60"/></a></p>
                <p class='has-text-grey-light is-size-8'>{{ props.row.primary_media.width }} x {{ props.row.primary_media.height }}</p>
              </div>
              <div v-else>
                <p><a href="https://via.placeholder.com" target="_blank"><img class="rounded-lg" src="https://via.placeholder.com/60" width="60" height="60"/></a></p>
                <p class='has-text-grey-light is-size-8'>60 x 60</p>
              </div>
            </b-table-column>
            <!-- Name -->
            <b-table-column
              :visible="true"
              label="Product"
              v-slot="props">
              <router-link :to="{ name: 'proposal.detail.product', params: { id: proposal.id, product: props.row.id } }" class="has-text-info has-text-weight-bold">{{ props.row.parent_name }}</router-link>
              <p>
                <span class="has-text-grey-light">Product SKU </span>
                <span>{{ props.row.parent_identifier }}</span>
              </p>
            </b-table-column>
            <!-- Cost Range -->
            <!-- <b-table-column
              :visible="true"
              label="Cost"
              cell-class="nowrap"
              v-slot="props">
              <p v-if="props.row.cost_range">
                <PriceRange :range="props.row.cost_range" />
              </p>
              <p v-else class="has-text-grey-light">--</p>
            </b-table-column> -->
            <!-- Price Range -->
            <!-- <b-table-column
              :visible="true"
              label="Price"
              cell-class="nowrap"
              v-slot="props">
              <p v-if="props.row.price_range">
                <PriceRange :range="props.row.price_range" />
              </p>
              <p v-else class="has-text-grey">--</p>
            </b-table-column> -->
            <!-- Supplier -->
            <b-table-column
              v-if="context.isMerchant()"
              label="Supplier"
              header-class="nowrap is-hidden-mobile"
              cell-class="nowrap is-hidden-mobile"
              width="200"
              v-slot="props">
              <p>{{ props.row.brand.name }}</p>
            </b-table-column>
            <!-- # Variants -->
            <b-table-column
              :visible="true"
              label="# of Items"
              header-class="nowrap"
              cell-class="nowrap"
              centered
              v-slot="props">
              <p>{{ props.row.num_variants }}</p>
            </b-table-column>
          </template>
          <template #empty>
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No active products found!' }}
            </div>
          </template>
          </b-table>
        </div>
      </div>
      <b-pagination
        :total="total"
        :current.sync="currentPage"
        :rounded="true"
        :per-page="perPage"
        @change="paginate">
      </b-pagination>
    </div>
    <!-- Product Detail Modal -->
    <b-modal
      v-model="showProductDetailModal"
      has-modal-card
      full-screen
      :can-cancel="false"
      scroll="keep"
      @close="product = null">
      <ProductDetailModal v-if="product !== null" :proposal="proposal" :product="product" />
    </b-modal>
    <!-- Remove Products Modal -->
    <b-modal
      has-modal-card
      :active.sync="(showRemoveProductsModal)"
      :can-cancel="['escape', 'x']"
      ><RemoveProposalProductsModal :proposal="proposal" :products="selected" @products:removed="this.load(true)" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
// import PriceRange from '../components/Products/PriceRange'
import ProductDetailModal from '../components/Products/ProductDetailModal'
import ProposalProductFilters from '../components/Detail/ProposalProductFilters'
import RemoveProposalProductsModal from '@/components/Models/Proposal/Modals/RemoveProposalProducts'

export default {
  components: {
    // PriceRange,
    RemoveProposalProductsModal,
    ProductDetailModal,
    ProposalProductFilters
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/proposals/' + this.proposal.id + '/products/?is_rejected=0'
    },
    canRemoveItems () {
      if (this.context.isSupplier()) {
        const allowed = ['draft', 'revised']
        if (allowed.includes(this.proposal.status)) {
          return true
        }
      }
      if (this.context.isMerchant()) {
        const allowed = ['proposed']
        if (allowed.includes(this.proposal.status)) {
          return true
        }
      }
      return false
    }
  },
  data () {
    return {
      key: 'proposal.products.' + this.proposal.id,
      product: null,
      showRemoveProductsModal: false,
      showProductDetailModal: false,
      selected: [],
      perPage: 12
    }
  },
  methods: {
    showProductDetail (product) {
      this.product = product
      this.showProductDetailModal = true
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable],
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
