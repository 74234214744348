<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Proposal Department</h1>
        </div>
      </div>
      <!-- Credentials -->
      <div class="py-md mb-lg">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Department Settings</p>
            <p class="has-text-grey is-size-7">
              Manage proposal department template and ruleset settings.
            </p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-lg mb-xxs">
              <div v-if="!loading && department !== null" class="pa-lg pb-xl">
                <!-- Name -->
                <div class="mb-lg">
                  <b-field
                    label="Proposal Department Name"
                    :type="('name' in errors) ? 'is-danger' : ''"
                    :message="('name' in errors) ? errors['name'][0] : ''"
                    expanded>
                    <b-input
                      type="text"
                      v-model="name"
                      size="is-medium"
                      placeholder="Name"
                    ></b-input>
                  </b-field>
                </div>
                <!-- Template -->
                <div class="mb-lg">
                  <b-field label="Product Import Template">
                    <TemplateSelect direction="import" datatype="products" v-model="template" :id="department.template.id" size="is-medium" />
                  </b-field>
                </div>
                <!-- Supplier Ruleset -->
                <div class="mb-lg">
                  <div class="mb-sm">
                    <p><label class="label">Supplier Ruleset</label></p>
                    <p class="is-size-7 has-text-grey">Select the attribute validations that suppliers must meet.</p>
                  </div>
                  <b-field>
                    <b-select v-model="supplierRuleset" :loading="loading" :disabled="loading" expanded size="is-medium" placeholder="Select Ruleset...">
                      <option v-for="ruleset in rulesets" :key="ruleset.id" :value="ruleset">{{ ruleset.name }}</option>
                    </b-select>
                  </b-field>
                </div>
                <!-- Merchant Ruleset -->
                <div class="mb-lg">
                  <div class="mb-sm">
                    <p><label class="label">Merchant Ruleset</label></p>
                    <p class="is-size-7 has-text-grey">Select the attribute validations that you must meet.</p>
                  </div>
                  <b-field>
                    <b-select v-model="merchantRuleset" :loading="loading" :disabled="loading"  expanded size="is-medium" placeholder="Select Ruleset...">
                      <option v-for="ruleset in rulesets" :key="ruleset.id" :value="ruleset" >{{ ruleset.name }}</option>
                    </b-select>
                  </b-field>
                </div>
                <!-- Sample Template URL  -->
                <div class="mb-lg">
                  <b-field
                    label="Sample Template URL (optional)"
                    :type="('sample_template_url' in errors) ? 'is-danger' : ''"
                    :message="('sample_template_url' in errors) ? errors['sample_template_url'][0] : ''">
                    <b-input
                      type="text"
                      v-model="sampleTemplateUrl"
                      size="is-medium"
                      placeholder="Enter URL..."
                    ></b-input>
                  </b-field>
                </div>
                <!-- Owner  -->
                <div class="mb-lg">
                  <b-field
                    label="Department Merchandiser"
                    :type="('department_owner_id' in errors) ? 'is-danger' : ''"
                    :message="('department_owner_id' in errors) ? errors['department_owner_id'][0] : ''">
                    <b-select
                      v-model="departmentOwner"
                      size="is-medium"
                      placeholder="Select User..."
                      expanded>
                      <option v-for="user in users" :key="user.id" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
                    </b-select>
                  </b-field>
                </div>
                <!-- Manager -->
                <div class="mb-lg">
                  <b-field
                    label="Department Manager"
                    :type="('department_manager_id' in errors) ? 'is-danger' : ''"
                    :message="('department_manager_id' in errors) ? errors['department_manager_id'][0] : ''">
                    <b-select
                      v-model="departmentManager"
                      size="is-medium"
                      placeholder="Select User..."
                      expanded>
                      <option v-for="user in users" :key="user.id" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
                    </b-select>
                  </b-field>
                </div>
                <!-- Status  -->
                <div class="mb-lg">
                  <div class="mb-sm">
                    <p><label class="label">Status</label></p>
                  </div>
                  <b-field>
                    <b-select v-model="isActive" expanded size="is-medium">
                      <option value="1" >Active</option>
                      <option value="0" >Inactive</option>
                    </b-select>
                  </b-field>
                  <b-message
                    v-if="isStatusError"
                    type="is-warning"
                    aria-close-label="Close message">
                    {{ `There are active proposals in your account under ${this.name}. Please accept/reject all such proposals to change the status.` }}
                  </b-message>
                </div>
              </div>
              <!-- Loading -->
              <div v-else>
                <loading message="Loading department..." />
              </div>
            </div>
            <div class="has-background-white rounded-lg pa-md">
              <div class="columns">
                <div class="column is-hidden-mobile">
                  <div class="px-sm has-text-grey-light">
                    <i v-if="ready">Click 'Save Preference' to save your changes</i>
                  </div>
                </div>
                <div class="column is-narrow">
                  <b-button
                    type="is-primary rounded-lg"
                    size="is-medium"
                    :disabled="saving || !ready"
                    :loading="saving"
                    @click.prevent="save"
                    >Save
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TemplateSelect from '@/components/Models/Template/TemplateSelect'

export default {
  components: {
    TemplateSelect
  },
  computed: {
    ...mapGetters(['app', 'api', 'auth', 'context']),
    breadcrumbs () {
      return {
        current: '#' + this.$route.params.id,
        history: [
          { label: 'Settings', name: 'settings' },
          { label: 'Proposal Departments', name: 'departments' }
        ]
      }
    },
    loading () {
      return (this.loadingRulesets || this.loadingDepartment || this.loadingUsers)
    },
    ready () {
      return (
        this.name &&
        this.template &&
        this.supplierRuleset &&
        this.merchantRuleset &&
        this.departmentManager &&
        this.departmentOwner
      )
    }
  },
  data () {
    return {
      loadingDepartment: true,
      loadingRulesets: true,
      loadingUsers: true,
      users: [],
      rulesets: [],
      department: null,
      name: null,
      template: null,
      supplierRuleset: null,
      merchantRuleset: null,
      sampleTemplateUrl: null,
      departmentOwner: null,
      departmentManager: null,
      isActive: null,
      saving: false,
      isStatusError: false,
      errors: {}
    }
  },
  methods: {
    loadDepartment () {
      this.loadingDepartment = true
      const endpoint = this.context.route + '/departments/' + this.$route.params.id + '/'
      this.$http.get(endpoint).then(response => {
        this.loadRulesets()
        this.department = response.data
        this.name = this.department.name
        this.sampleTemplateUrl = this.department.sample_template_url
        this.departmentOwner = (this.department.owner_user) ? this.department.owner_user.id : null
        this.departmentManager = (this.department.manager_user) ? this.department.manager_user.id : null
        this.isActive = (this.department.is_active) ? 1 : 0
        this.loadingDepartment = false
      })
    },
    loadUsers () {
      this.loadingUsers = true
      const endpoint = this.context.route + '/users/'
      const query = {
        params: {
          mini: 1,
          pagination: 0,
          order_by: 'first_name',
          type: 'standard',
          status: 'active,invited'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.users = response.data.results
        this.loadingUsers = false
      })
    },
    loadRulesets () {
      this.loadingRulesets = true
      const endpoint = this.context.route + '/compatibility/rulesets/'
      const query = {
        params: {
          order_by: 'name'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.rulesets = response.data.results
        response.data.results.forEach(ruleset => {
          if (this.department.brand_ruleset.id === ruleset.id) {
            this.supplierRuleset = ruleset
          }
          if (this.department.retailer_ruleset.id === ruleset.id) {
            this.merchantRuleset = ruleset
          }
        })
        this.loadingRulesets = false
      })
    },
    async validateStatus () {
      const activeBoolToNum = (this.department.is_active) ? 1 : 0
      if (activeBoolToNum === parseInt(this.isActive) ||
        (activeBoolToNum === 0 && parseInt(this.isActive) === 1)) {
        return true
      }
      const endpoint = `${this.context.route}/proposals/?retailer_id=${this.context.partner.id}&status=proposed,draft,revised&department_id=${this.department.id}`
      const { data: { count } } = await this.$http.get(endpoint)
      if (count === 0) {
        return true
      }
      return false
    },
    async save () {
      this.saving = true
      this.isStatusError = false
      const isValidStatus = await this.validateStatus()
      if (!isValidStatus) {
        this.isStatusError = true
        this.saving = false
        this.isActive = 1
        return
      }
      const endpoint = this.context.route + '/departments/' + this.department.id + '/'
      const body = {
        name: this.name,
        template_id: this.template.id,
        brand_ruleset_id: this.supplierRuleset.id,
        retailer_ruleset_id: this.merchantRuleset.id,
        sample_template_url: this.sampleTemplateUrl,
        owner_user_id: this.departmentOwner,
        manager_user_id: this.departmentManager,
        description: null,
        is_active: this.isActive
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Proposal Department saved!' })
        this.$emit('reload')
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadUsers()
    this.loadDepartment()
  }
}
</script>
